<template>
  <b-toast
    id="success-toast"
    variant="success"
    solid
    style="display: block!important"
  >
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Запрос выполнен</strong>
      </div>
    </template>
    {{ message }}
  </b-toast>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({ message: 'notifications/message' })
};
</script>
